import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	Inject,
	Injectable,
	Optional,
	PLATFORM_ID,
	Renderer2,
	RendererFactory2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class StackAdaptService {
	private _doc: Document;
	private _renderer: Renderer2;

	public get isLoaded(): boolean {
		if (isPlatformBrowser(this._platformId)) {
			const pixelElement = this._doc.getElementById('stack-adapt-script');
			return !!pixelElement;
		}
		return false;
	}

	constructor(
		@Inject(DOCUMENT) private _injectedDocument: any,
		@Inject(PLATFORM_ID) private _platformId: Object,
		private rendererFactory: RendererFactory2,
		@Optional() private router: Router
	) {
		this._doc = _injectedDocument as Document;
		this._renderer = rendererFactory.createRenderer(null, null);

		if (router) {
			router.events
				.pipe(filter((event) => event instanceof NavigationEnd))
				.subscribe(() => {
					if (this.isLoaded) {
						this.track('PageView');
					}
				});
		}
	}

	public initialize(accountId: string): void {
		if (!isPlatformBrowser(this._platformId)) {
			return;
		}

		if (this.isLoaded) {
			console.warn('StackAdapt script is already initialized.');
			return;
		}

		this._addStackAdaptScript(accountId);
	}

	public remove(): void {
		this._removeStackAdaptScript();
	}

	public track(eventName: string, properties?: object): void {
		if (!isPlatformBrowser(this._platformId)) {
			return;
		}

		if (!this.isLoaded) {
			console.warn('StackAdapt script is not initialized.');
			return;
		}

		if (properties) {
			(window as any).saq('track', eventName, properties);
		} else {
			(window as any).saq('track', eventName);
		}
	}

	private _addStackAdaptScript(accountId: string): void {
		if (!isPlatformBrowser(this._platformId)) {
			return;
		}

		const pixelCode = `!function(s,a,e,v,n,t,z)
		{if(s.saq)return;n=s.saq=function(){n.callMethod?
		n.callMethod.apply(n,arguments):n.queue.push(arguments)};
		if(!s._saq)s._saq=n;n.push=n;n.loaded=!0;n.version='1.0';
		n.queue=[];t=a.createElement(e);t.async=!0;
		t.src=v;z=a.getElementsByTagName(e)[0];
		z.parentNode.insertBefore(t,z)}(window,document,'script',
		'https://tags.srv.stackadapt.com/events.js');
		saq('ts', '${accountId}');`;

		const scriptElement = this._doc.createElement('script');
		this._renderer.setAttribute(scriptElement, 'id', 'stack-adapt-script');
		this._renderer.setAttribute(scriptElement, 'type', 'text/javascript');
		this._renderer.setProperty(scriptElement, 'innerHTML', pixelCode);
		this._renderer.appendChild(this._doc.head, scriptElement);
	}

	private _removeStackAdaptScript(): void {
		if (!isPlatformBrowser(this._platformId)) {
			return;
		}

		const scriptElement = this._doc.getElementById('stack-adapt-script');
		if (scriptElement) {
			scriptElement.remove();
		}
	}
}
